const uuid = require('uuid');

// let axios = require('axios');
const cookies = require('js-cookie');

class BulldeskAnalytics {
  constructor(settings) {
    if (typeof settings.token !== 'undefined') {
      settings.app_id = settings.token;
    }

    if (typeof settings.app_id === 'undefined') {
      console.warn('Bulldesk: ID do app não identificado.');
    }

    this.settings = settings;

    this.cookie = 'bulldesk-id';
  }

  /**
   * Get value on local storage
   */
  getLocalStorage(key) {
    /**
     * Get 'bulldesk-settings' of the local storage
     */
    const settings =
      JSON.parse(window.localStorage.getItem('bulldesk-settings')) || {};

    /**
     * Return object of key
     */
    return settings[key];
  }

  hasClient() {
    return cookies.get(this.cookie) !== 'undefined';
  }

  getClient() {
    let client = cookies.get(this.cookie);

    if (typeof client === 'undefined') {
      client = uuid.v1();

      this.saveClient(client);
    }

    return client;
  }

  saveClient(client) {
    // console.log(this.getOriginalDomain());

    cookies.set(this.cookie, client, {
      expires: 30 * 12 * 2,
      domain: `.${this.getOriginalDomain()}`,
    });
  }

  getElement() {
    return document.getElementById('bulldesk-analytics');
  }

  getBulldeskDomain() {
    const element = this.getElement();

    if (element === null) {
      throw 'Bulldesk: Tag do script não encontrada.';
    }

    return element
      .getAttribute('src')
      .replace('assets.', '')
      .replace('static.', '')
      .replace('analytics.js', '')
      .replace('analytics.dev.js', '')
      .replace('analytics/track.js', '')
      .replace('salesflow.js', '')
      .replace('bulldesk.js', '');
  }

  getBaseDomain() {
    return window.location.hostname.replace('www.', '');
  }

  getOriginalDomain() {
    let i = 0;
    let domain = this.getBaseDomain();
    const p = domain.split('.');
    const s = `_gd${new Date().getTime()}`;

    while (i < p.length - 1 && document.cookie.indexOf(`${s}=${s}`) == -1) {
      domain = p.slice(-1 - ++i).join('.');
      document.cookie = `${s}=${s};domain=${domain};`;
    }

    document.cookie = `${s}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=${domain};`;

    return domain;
  }

  getReferrer() {
    return document.referrer;
  }

  getLocation() {
    return document.location.href;
  }

  getScreenResolution() {
    if (window.screen) {
      return `${window.screen.width}x${window.screen.height}`;
    }
  }

  getUrlParam(param) {
    const match = document.location.search.match(`(?:\\?|&)${param}=([^&#]*)`);

    return match && match.length === 2 ? decodeURIComponent(match[1]) : '';
  }

  serialize(obj, prefix) {
    const str = [];

    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        const k = prefix ? `${prefix}[${p}]` : p;
        const v = obj[p];

        str.push(
          typeof v === 'object'
            ? this.serialize(v, k)
            : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`,
        );
      }
    }
    return str.join('&');
  }

  /**
   * Set the client inputs.
   */
  setHiddenInputs(name, value) {
    if (value === 'undefined') {
      return;
    }

    let inputs = document.getElementsByName(`salesflow-${name}`);
    if(!inputs.length) {
      inputs = document.getElementsByName(`bulldesk-${name}`)
    }

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].type === 'hidden') {
        inputs[i].value = value;
      }
    }
  }

  track() {
    let tracked = window.BulldeskSettings.tracked || false;

    if (tracked === true) {
      return;
    }

    const data = {
      ac: this.settings.app_id,
      cn: this.getUrlParam('utm_campaign'),
      cs: this.getUrlParam('utm_source'),
      cm: this.getUrlParam('utm_medium'),
      ct: this.getUrlParam('utm_term'),
      cc: this.getUrlParam('utm_content'),
      rf: this.getUrlParam('ref'),
      d: this.getBaseDomain(),
      l: this.getLocation(),
      r: this.getReferrer(),
      sr: this.getScreenResolution(),
      t: document.title || '',
    };

    window.BulldeskSettings.domain = data.d;

    this.setHiddenInputs('domain', data.d);

    if (this.settings.debug) {
      console.log('Sending track request.');
    }

    data.c = this.getClient();

    window.BulldeskSettings.client = data.c;

    this.setHiddenInputs('client', data.c);

    this.request('hit', data);

    window.BulldeskSettings.tracked = true;
  }

  identify(data) {
    // Create or update lead. Do not convert.

    if (this.settings.debug) {
      console.log('Sending identify request.');
    }

    data.ac = this.settings.app_id;

    // this.request('identify', data);
  }

  convert(data) {
    // Convert a lead.

    if (this.settings.debug) {
      console.log('Sending convert request.');
    }

    data.ac = this.settings.app_id;

    this.request('convert', data);
  }

  request(route, data, ajax) {
    const domain = this.getBulldeskDomain();

    let url = `${domain}front/analytics/${route}`;

    if (
      (domain.indexOf('bulldesk.com.br') !== -1
      || domain.indexOf('salesflowcrm.com.br') !== -1)
      && !domain.includes('release') && !domain.includes('homolog')
    ) {
      url = `https://analytics.salesflowcrm.com.br/${route}`;
    }

    if (typeof ajax === 'undefined') {
      this.sendImage(url, data);
    } else {
      this.sendAjax(url, data);
    }

    if (this.settings.debug) {
      console.log('Request sent');
    }
  }

  sendImage(url, data) {
    const image = new Image();

    image.src = `${url}?${this.serialize(data)}`;
  }

  sendAjax(url, data) {
    axios.get(url, data);
  }

  call(queue) {
    for (let i = 0; i < queue.length; i++) {
      const current = queue[i];

      this[current[0]](current[1]);
    }
  }

  async waitForElm(selector, callback) {
    return new Promise(resolve => {
        if (document.querySelector(selector)) {
            callback();
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(mutations => {
            if (document.querySelector(selector)) {
                observer.disconnect();
                callback();
                resolve(document.querySelector(selector));
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
  }
}

window.BulldeskSettings = window.BulldeskSettings || {};

const settings = window.BulldeskSettings;
const analytics = new BulldeskAnalytics(settings);

const deniedCookie = analytics.getLocalStorage('deniedCookie');
const timestamp = new Date().getTime();
const isCookieDenied = deniedCookie !== undefined && (deniedCookie.value || deniedCookie.expiry < timestamp);

analytics.waitForElm('#allowTrackingButton', () => {
  if (deniedCookie === undefined) {
    const allowTrackingButton = document.getElementById('allowTrackingButton');

    allowTrackingButton.addEventListener('click', () => {
      analytics.track();
    });
  }  
});

if (settings.track !== false && deniedCookie !== undefined && !isCookieDenied) {
  analytics.track();
}

if (settings.identify !== false) {
  analytics.identify(settings.user || {});
}

analytics.call(window.Bulldesk.q);
